@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900&display=swap');
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {display: block;}
audio,canvas,video {display: inline-block;}
audio:not([controls]) {display: none; height: 0;}
[hidden] {display: none;}
html {font-family: sans-serif; /* 1 */-ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */}
a:focus {outline: none !important;}
a:active,a:hover {outline: 0;}
h1 {font-size: 2em;margin: 0.67em 0;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
mark {background: #ff0;color: #000;}
code,kbd,pre,samp {font-family: monospace, serif;font-size: 1em;}
pre {white-space: pre-wrap;}
q {quotes: "\201C" "\201D" "\2018" "\2019";}
small {font-size: 80%;}
sub,sup {font-size: 75%;line-height: 0;position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
img {border:0; max-width:100%;}
a{text-decoration:none !important;}
svg:not(:root) {overflow: hidden;}
figure {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}
legend {border: 0; /* 1 */ padding: 0; /* 2 */}
button,input,select,textarea {font-family: inherit; /* 1 */font-size: 100%; /* 2 */margin: 0; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
a{text-decoration:none;}
button,html input[type="button"], /*1*/input[type="reset"],input[type="submit"] {-webkit-appearance: button; /* 2 */ cursor: pointer; /* 3 */}
button[disabled],html input[disabled] {cursor: default;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; /* 1 */ padding: 0; /* 2 */}
input[type="search"] {-webkit-appearance: textfield; /* 1 */-moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */ box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,
input::-moz-focus-inner {border: 0;padding: 0;}
textarea {overflow: auto; /* 1 */ vertical-align: top; /* 2 */}
table {border-collapse: collapse; border-spacing: 0;}
body{
  font-family: $bodyFontFamily;
  color: $bodyTextColor;
  background:#fff;
}
.collapse{display:block;}
#NavBtn{display: none;}
/*#CloseNav{display: none;}*/
.logo{padding:10px 15px; background:#333;}
.logo img{max-width:100%;}
.CloseN{display: none; background: #fff; padding: 4px;
  a{
    display: block;
  }
}
.CloseN i{
  font-size: 44px;
}

.topboxb{
 padding: 6px 0;
 background: #ad332b;
 color: #fff;
 text-align: right;
ul{
  margin: 0;
  padding: 0;
  li{
    display: inline-block;
    font-size: 14px;
    padding: 0 15px;
    margin: 0;
    // margin-left:10px;
    border-right: #a55 1px solid;
    // position: relative;
    &:last-child { 
      border-right: none;  
    }
    i{
      margin-right: 4px;
    }
    // i{
    //   font-size: 16px;
    //   // right: 15px;
    //   top: 2px;
    //   position: absolute;
    //   left: 0;
    // }
    a{
      color: #fff;
      &:hover{
        color: #fff600;
      }
    }
  }
}
}
.mlogo{
  // display: none;
}
.headerM{
  margin: 0;
  padding: 8px 0;
  .rightBx{
    text-align: right;
  }
}
.menuBx{
  // .bg-dark{
  //   background: #373f6e !important;
  //   // border-bottom: #ff0000 4px solid;
  // }
  .navbar-collapse{
    flex-grow:0 !important;
  }
  .navbar-dark .navbar-nav .nav-link{color: rgba(255,255,255,0.9);text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;}

}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #fff;
  background: rgba(255,255,255,0.1);
}
// .EventBox{
//   margin: 0;
//   padding: 45px 0;
//   .box{
//     margin:10px 0;
//     &:hover{
//       img{
//         border:#ad332b 1px solid;
//       }
//     }
//     .boximg{
//       margin:10px 0;
//        height: 177px;
//       img{
//         width: 100%;
//         height: 100%;
//       }
//     }
//    a{
//      h4{
//       margin:8px  0;
//       color: #000;
//       font-size: 18px;
//       font-weight: 600;
//     }
//       h6{
//       margin:8px  0;
//       color: #f00;
//       font-size: 14px;
//       font-weight: 600;
//     }
//     p{
//       font-size: 14px;
//       color: #222;
//     }
//    }
//   }
//   h3{
//     font-weight: 200;
//     text-align: center;
//     text-transform: uppercase;
//     margin-bottom: 15px;
//     strong{
//       font-weight: 800;
//     }
//   }
// }
 .listBx{
  background: #fff;
  padding: 5px;
  border:#ccc 1px solid;
  margin: 0;
    img{
      width: 100%;
      height: 220px;
    
  }  
  h5{
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    color: #000;
    font-weight: 500;
  }
}
.hboxm{
  margin: 0;
  background: #fff;
  padding: 45px 0;
  h2{
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    span{
      font-weight: 200;
    }
  }
  .boxm{
    margin:15px 0;
    border: #eee 2px solid;
    position: relative;
    &:hover{
      border-color: #f00;
      h5{
        background: #f00;
        color: #fff;
      }
    }
  .boxi{
    margin: 0;
    img{
      width: 100%;
      height: 220px;
    }
  }
  h5{
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 4px 10px;
    margin: 0;
    font-size: 14px;
    color: #000;
  }
  }
}




.videog{
  margin: 15px 0;
  iframe{
    width: 100% !important;
    height: 210px !important;
  }
}

.gallerbx{
  text-align: center;
  margin: 10px 0;
  border: #000 1px solid;
  h5{
    text-align: center;
    font-size: 14px;
    padding-top: 15px;
  }
  img{
    width: 100%;
    height: 180px;
  }
}



 .footerB{
      margin:0;
      padding:50px 0;
      background: #333333;
      a{
        color: #fff;
      }
      i{
        color: #fff;
      }
      h3{
        font-size:20px;
        /*text-transform: uppercase;*/
        color:#fff;
        padding-bottom:10px;
        font-weight: 400;
        position: relative;
        margin-bottom: 15px;
        font-weight: 200;
        /*&:after {
        position: absolute;
        content: '';
        width: 15%;
        height: 2px;
        border-bottom: 2px solid #f4b04d;
        bottom: -2px;
        margin: auto;
        left: 0;
      }*/
      }
      p{
        color:#fff;
        font-size:13px;
      }
      ul{
        margin:0;
        padding:0;
        li{
          display:block; 
          font-size:13px; 
          line-height: 25px;
          a{
            color:#fff;
            padding:4px 0;
            display:inline-block;
            -webkit-transition:all 0.4s ease;-o-transition:all 0.4s ease;
            transition:all 0.4s ease;
            position:relative;
            &:before{
            position:absolute;
            top:50%;
            left:0;
            content:'';
            width:0;
            height:1px;
            background-color:#f4b04d;
            -webkit-transition:all 0.4s ease;-o-transition:all 0.4s ease;
            transition:all 0.4s ease;
          }
           &:hover{
            color:#f4b04d;
            padding-left:25px;
          }
           &:hover:before{
            width:20px;
          }
          }

        }
      }
 }
// .newsbox{
//   background: #fff;
//   padding: 15px;
//   border-radius: 5px;
//   box-shadow: $boxshadow;
//   li{
//     font-size: 13px;
//     color: #333;
//     a{
//       color: #f00;
//     }
//   }
//   h3{
//     font-style: 23px;
//     font-weight: 200;
//     margin-bottom: 15px;
//     color: #000;
//     b{
//       font-weight: 800;
//     }
//   }
// }
.contant{
  padding: 55px 0;
  text-align: center;
  background: #333333;
  h1{
    margin: 0;
    padding: 0 0 25px 0;
    text-transform: uppercase;
    color: #fff;
    font-weight: 800;
    span{
      font-weight: 200;
    }
  }
  p{
      font-weight: 300;
      color: #fff;
      /*line-height: 29px;*/
      strong{
        font-style: italic !important;
      }
    }
    a{
      color: #ffd200;
      display: inline-block;
      margin-top: 15px;
    }
}

.menbox{
  background: #eee;
  padding: 45px 0;
  h3{
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    span{
      font-weight: 300;
    }
  }
  .rbox{
    margin: 0;
    background: #fff;
    padding:25px 0;
    text-align: center;
    margin: 25px 0;
    &:hover{
      background: #e33324;
      .iconbx{
        background: #fff;
        i{
          color: #e33324;
        }
      }
      h4{
        color: #fff;
      }
      p{
        color: #fff;
      }
    }
    p{
      color: #333;
      font-size: 14px;
    }
    h4{
      padding: 5px 0;
      text-transform: uppercase;
      font-size: 16px;
      margin-top: 15px;
    }
    .iconbx{
      border-radius: 100%;
      height: 180px;
      width: 180px;
      text-align: center;
      margin: auto;
      background: #e33324;
      // padding: 10px 0;
      img{
        border-radius: 100%;
        overflow: hidden;
        height: 100%;
        width: 100%;
      }
    }
  }
}


 .footertop{
  background:#fff; 
  padding:15px 0;
  color:#000;
  font-size:14px;
  line-height: 29px;
  font-weight: 300;
   .footr{
    text-align:right;
    a{
      color:#000;
    }
  }
}







// .sapbx{
//   margin: 0;
//   padding: 35px 0;
//    h3{
//     font-weight: 800;
//     text-align: center;
//     text-transform: uppercase;
//     padding-bottom: 25px;
//     span{
//       font-weight: 300;
//     }
//   }
//   .boxm{
//     margin: 0;
//     height: 120px;
//     margin: auto;
//     text-align: center;
//     img{
//         transition: filter .5s ease-in-out;
//   -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
//   filter: grayscale(0%); /* FF 35+ */
//   max-height: 100%;
//   max-width: 100%;
//   width: auto;
//    margin: auto;
//     text-align: center;

//   &:hover{
//     webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
//   filter: grayscale(100%); /* FF 35+ */
//     }
//   }
//   }
// }

.pagebox{
  min-height: 450px;
  padding: 25px 0;
  .boxback{
    background: #fff;
    padding: 15px;
    box-shadow: $boxshadow;
  }
}
.boxpp{
  margin: 0;
  .pimg2{
    margin:20px 0;
    height: 280px;
     img{
        width: 100%;
        height: 100%;
      }
  }
  h5{
    font-size: 14px;
    color: #000;
    text-align: center;
  }
 
}
.proddetails{
  margin:15px 0;
  h3{
    margin-bottom: 15px;
  }
  p{
    font-size: 14px;
    font-weight: 350 !important;
    line-height: 25px;
  }
}
.bannerAb{
  margin: auto;
  height: 250px;
  overflow: hidden;
  background-color: #000;
  position: relative;
  img{
    width: 100%;
    height: auto;
    opacity: 0.4;
  }
  h1{
   text-align: center;
   position: absolute;
   top: 30%;
   bottom: 0;
   left: 0;
   right: 0;
   text-transform: uppercase;
   color: #fff;
    margin: auto;
    font-size: 3em;
    font-weight: 300;
  }
}


.bannerBx{
   .jssorl-009-spin img {
            animation-name: jssorl-009-spin;
            animation-duration: 1.6s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes jssorl-009-spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
        
        /*jssor slider bullet skin 032 css*/
        .jssorb032 {position:absolute;}
        .jssorb032 .i {position:absolute;cursor:pointer;}
        .jssorb032 .i .b {fill:#fff;fill-opacity:0.7;stroke:#000;stroke-width:1200;stroke-miterlimit:10;stroke-opacity:0.25;}
        .jssorb032 .i:hover .b {fill:#000;fill-opacity:.6;stroke:#fff;stroke-opacity:.35;}
        .jssorb032 .iav .b {fill:#000;fill-opacity:1;stroke:#fff;stroke-opacity:.35;}
        .jssorb032 .i.idn {opacity:.3;}
        
        /*jssor slider arrow skin 051 css*/
        .jssora051 {display:block;position:absolute;cursor:pointer;}
        .jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
        .jssora051:hover {opacity:.8;}
        .jssora051.jssora051dn {opacity:.5;}
        .jssora051.jssora051ds {opacity:.3;pointer-events:none;}
}