@media all and (max-width: 824px){
  .topbox{
    display: none;
  }
  .mlogo{
    display: block;
  }
  .logob{
    display: none !important;
  }
  .menuBx{
     .bg-dark{
      background: #fff !important;
       .nav-item{
        a{
          color: rgba(000,000,000,0.9) !important;
        }
       }
     }
  }
  .navbar-toggler{
    background: #000 !important;
  }
  .navbar-dark .navbar-toggler{
    color:rgba(255,255,255,1)  !important;
    border-color: rgba(255,255,255,0.1);
  }
.navbar-brand{
  img{
    width: 190px;
  }
}
.topboxb {
  text-align: center;
  ul{
    text-align: left;
    li{
      font-size: 14px;
      padding:10px 0 0 5px;
    }
  }
}
.headerM{
  padding: 5px 0;
}
.bannerAb{
  margin: auto;
  height: 100px;
  overflow: hidden;
  background-color: #000;
  position: relative;
  img{
    width: 100%;
    height: auto;
    opacity: 0.4;
  }
  h1{
    font-size: 1.2em;

  }
}
.newsbox{
  margin-top: 15px;
}
.contant {
   h1{
    font-size: 1.8em;
     span{
    font-size: 1.5em;
   }
   }
}
.welcomeBx{
   h1{
    font-size: 1.8em;
     span{
    font-size: 1.5em;
   }
   }
   p{
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    font-weight: 400;
   }

}

.Prodhome {
  h2{
    font-size: 1.5em;
  }
  .pimg {
    .pimg1{
      height: 180px;
    }
    h5{
      font-size: 12px;
    }
  }
}
.prodboxH{
  h3{
    font-size: 1.5em;
  }
}
.gallBox{
   h2{
    font-size: 1.5em;
    strong{

    }
    }
    img{
      height: 130px;
    } 
}
.Franchisebx{
  h1{
    font-size: 1.5em;
  }
}
.footertop{
  text-align: center;
  .footr{
    text-align: center;
  }
}
.navbar-toggler{
  background: #fff;
  margin-right: 10px;
}
.ServiceBx{
  h1{
   font-size: 1.8em;
  }
}
.Hcont{
font-size: 1.8em;
strong{
  padding-left: 0;
}
}
}


@media (min-width: 992px){
    .dropdown-menu .dropdown-toggle:after{
      border-top: .3em solid transparent;
        border-right: 0;
        border-bottom: .3em solid transparent;
        border-left: .3em solid;
    }

    .dropdown-menu .dropdown-menu{
      margin-left:0; margin-right: 0;
    }

    .dropdown-menu li{
      position: relative;
    }
    .nav-item .submenu{ 
      display: none;
      position: absolute;
      left:100%; top:-7px;
    }
    .nav-item .submenu-left{ 
      right:100%; left:auto;
    }

    .dropdown-menu > li:hover{ background-color: #f1f1f1 }
    .dropdown-menu > li:hover > .submenu{
      display: block;
    }
  }

  @media only screen and (max-width: 767px) {

#NavBtn{display: block;}
#navbarToggler{display: block;}

#MainNav{
    position: fixed;
    width: 80%;
    /*height: calc(100% - 38px);*/
    height: 100%;
    top: 0px;
    left: -100%;
    z-index: 9;
    transition: all 0.5s linear;
    overflow:auto;
    background: #fff;
}


#MainNav.Show{left: 0;}
.CloseN{left:-90%; display: block; position:fixed; top:0px;  display: block;transition: all 0.5s linear;}
.Show2{left:80%;}

}